'use client';

import React from 'react';
import { User } from '@ghosts-inc/design-system/icons';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { usePathname } from 'next/navigation';
import { AnalyticsEventNames } from '@/analytics/Event.types';
import { analytics } from '@/analytics/segment';
import Link from '@/components/Link';
import { BuyerStates } from '@/legacy/buyers/buyerSummary.model';
import { useGhostStore } from '@/legacy/store';
import { useBuyerRecommendationsFeatureFlag } from '../../app/(authenticated)/(marketplace)/preferences/hooks/featureFlags';
import { useIsDisplayBuyerLink } from './hook/useIsDisplayBuyerLink';
import { useIsDisplayReferrerLink } from './hook/useIsDisplayReferrerLink';
import { useIsDisplaySellerLink } from './hook/useIsDisplaySellerLink';
import { handleSignOut } from './actions';

export const UserMenu = ({ isDark = false, isDarker = false }) => {
  const { setUser, user } = useGhostStore((state) => ({
    setUser: state.setUser,
    user: state.user,
  }));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = async () => {
    analytics.track(AnalyticsEventNames.LOGOUT_CLICKED);
    await handleSignOut();
    handleClose();
    setUser(null);
  };

  const showSellerLink = useIsDisplaySellerLink();
  const showBuyerLink = useIsDisplayBuyerLink();
  const showReferrerLink = useIsDisplayReferrerLink();
  const { isBuyerRecommendationsEnabled } = useBuyerRecommendationsFeatureFlag();
  const pathname = usePathname();

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        sx={{
          ...(isDarker
            ? {
                backgroundColor: 'surface.darker',
                '&:hover': {
                  backgroundColor: 'surface.dark',
                },
                color: 'surface.contrastText',
              }
            : isDark
              ? {
                  backgroundColor: 'surface.light',
                  '&:hover': {
                    backgroundColor: 'surface.main',
                  },
                  color: 'surface.contrastText',
                }
              : {
                  '&:hover': {
                    backgroundColor: 'surface.lighter',
                  },
                }),
        }}
      >
        <User />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!pathname.startsWith('/referrer') &&
          isBuyerRecommendationsEnabled &&
          !pathname.startsWith('/onboarding') &&
          !pathname.startsWith('/seller') && (
            <MenuItem
              sx={{
                minWidth: 224,
              }}
            >
              <Link
                underline="none"
                href="/preferences"
                rel="noopener noreferrer"
                onClick={handleClose}
              >
                Preferences
              </Link>
            </MenuItem>
          )}
        {pathname.startsWith('/referrer') && (
          <MenuItem
            sx={{
              minWidth: 224,
            }}
          >
            <Link
              underline="none"
              href="/referrer/profile"
              rel="noopener noreferrer"
              onClick={handleClose}
            >
              Profile
            </Link>
          </MenuItem>
        )}
        {showSellerLink && (
          <MenuItem
            sx={{
              minWidth: 224,
            }}
          >
            <Link
              underline="none"
              href="/seller"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
            >
              Switch to Seller
            </Link>
          </MenuItem>
        )}
        {showBuyerLink && user && (
          <MenuItem
            sx={{
              minWidth: 224,
            }}
          >
            <Link
              underline="none"
              href={user.buyer?.state === BuyerStates.ACTIVATED ? '/' : '/onboarding/buyer'}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
            >
              {user.buyer?.state === BuyerStates.ACTIVATED
                ? 'Switch to Buyer'
                : 'Continue Buyer Onboarding'}
            </Link>
          </MenuItem>
        )}
        {showReferrerLink && (
          <MenuItem
            sx={{
              minWidth: 224,
            }}
          >
            <Link
              underline="none"
              href="/referrer"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClose}
            >
              Switch to Referrer
            </Link>
          </MenuItem>
        )}
        <MenuItem
          data-testid="button-userMenu"
          sx={{
            minWidth: 224,
          }}
        >
          <MuiLink underline="none" onClick={onLogoutClick} data-testid="button-signOut">
            Sign Out
          </MuiLink>
        </MenuItem>
      </Menu>
    </>
  );
};
