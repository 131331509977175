'use client';

import { useEffect, useRef } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { DATADOG_SERVICE, DATADOG_SITE } from './constants';
import { DatadogVars } from './types';

export default function Datadog({ isLocal, ...vars }: DatadogVars & { isLocal: boolean }) {
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    const { clientToken, applicationId, env, version, backendTraceScheme, backendTraceHost } = vars;
    if (isLocal || initialized.current) return;

    const allowedTracingUrls: RumInitConfiguration['allowedTracingUrls'] = [];

    if (!!backendTraceHost && !!backendTraceScheme) {
      allowedTracingUrls.push({
        match: `${backendTraceScheme}://${backendTraceHost}/`,
        propagatorTypes: ['tracecontext'],
      });
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: DATADOG_SITE,
      service: DATADOG_SERVICE,
      env,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls,
    });

    datadogLogs.init({
      clientToken,
      site: DATADOG_SITE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: DATADOG_SERVICE,
      env,
      forwardConsoleLogs: 'all',
      forwardReports: 'all',
      version,
    });

    initialized.current = true;
  }, [isLocal, vars]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
