'use client';

import { StarFilled } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const quotes = [
  {
    body: `“Ghost's seamless transactions and incredible logistics support empower me to close more deals than ever before.”`,
    author: 'Streetwear Reseller',
  },
  {
    body: `“Access to Ghost's inventory of sought-after brands has fueled a remarkable surge in my business and increased margins.”`,
    author: 'Luxury Wholesaler',
  },
  {
    body: `“Ghost is the most impactful surplus disrupter I've seen in my 25 years in business. I view them as an extension of my business.”`,
    author: 'Amazon Reseller',
  },
];
export default function Quotes({ isDarkMode = false }: { isDarkMode?: boolean }) {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={8}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 6000, disableOnInteraction: false }}
      style={{ width: '70%', minWidth: 320, height: '20%' }}
    >
      {quotes.map((quote) => (
        <SwiperSlide key={quote.body}>
          <Box
            p={2.5}
            style={{
              background: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.37)',
            }}
            borderRadius={2}
          >
            <Typography
              variant="bodyLargeLight"
              mb={2.5}
              color={isDarkMode ? 'typographyDarkInverted' : 'typographyLightDisabled'}
              sx={{ opacity: 0.5 }}
            >
              {quote.body}
            </Typography>
            <Stack direction="row" alignItems="center" mb={1.5}>
              {new Array(5).fill(0).map((_, i) => (
                <StarFilled
                  key={i}
                  sx={(theme) => ({
                    opacity: 0.75,
                    color: isDarkMode
                      ? theme.palette.typographyDarkInverted.main
                      : theme.palette.typographyLightDisabled.main,
                  })}
                />
              ))}
            </Stack>
            <Typography
              variant="bodySmallLight"
              color={isDarkMode ? 'typographyDarkInverted' : 'typographyLightDisabled'}
              sx={{ opacity: 0.3 }}
            >
              {quote.author}
            </Typography>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
